import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import './plugins/base'
import ImageLoader from './components/Common/imageLoader'
import 'vuetify/dist/vuetify.min.css'
Vue.component('ImageLoader', ImageLoader)

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// import Viewer from 'v-viewer'
// Vue.use(Viewer)
// import 'viewerjs/dist/viewer.css'
import ServerError from './components/Common/500'
Vue.component('ServerError', ServerError)

window.axios = require('axios')

// Vue.prototype.mediaURL = "http://192.168.54.171:3500/file/get/"
// Vue.prototype.mediaUURL = "http://192.168.54.171:3500/wp/";
// axios.defaults.baseURL = "http://192.168.54.171:3500";
// Vue.prototype.ipURL = "http://192.168.54.171:3500"

Vue.prototype.mediaURL = "https://api.myjesusapp.com/file/get/"
axios.defaults.baseURL = "https://api.myjesusapp.com";
Vue.prototype.mediaUURL = "https://api.myjesusapp.com/wp/";
Vue.prototype.ipURL = "https://api.myjesusapp.com"

// axios.defaults.baseURL = "http://192.168.137.215:3500";
// Vue.prototype.ipURL = "http://192.168.137.215:3500"

// axios.defaults.baseURL = 'http://localhost:3500';
// Vue.prototype.ipURL = 'http://localhost:3500';
// //Vue.prototype.mediaURL = "https://localhost:3500/wp/";
// Vue.prototype.ipURL = "https://localhost:3500"
// Vue.prototype.mediaURL = "http://localhost:3500/file/get/"
// Vue.prototype.mediaUURL = "http://localhost:3500/wp/";


// axios.defaults.baseURL = 'http://localhost:3500';
// app.config.globalProperties.baseURL = "http://localhost:3500";
// app.config.globalProperties.mediaURL = "http://localhost:3500/wp/"



// Vue.prototype.mediaUURL = "http://localhost:5001/file/get/"
// axios.defaults.baseURL = "http://localhost:5001";
// Vue.prototype.mediaURL = "http://localhost:5001/wp/";
// Vue.prototype.ipURL = "http://localhost:5001"

axios.defaults.timeout = 60000;



Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,

    render: h => h(App)
}).$mount('#app')